<template>
    <div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop" @show="filterOpen">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                    class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <!--------------- Плановый период ------------------->
                    <div class="filter-block">
                        <b-form-group label="Плановый период">
                            <multiselect
                                ref="bcPlanYear"
                                v-model="planPeriod"
                                track-by="name"
                                label="name"
                                placeholder="Выбрать год"
                                :options="years"
                                :searchable="false"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="chgRegionOrYear"
                            >
                            </multiselect>
                        </b-form-group>
                    </div>
                    <div class="filter-block" v-if="listReg !== null">
                        <b-form-group label="Область/район">
                            <multiselect
                                v-model="curReg"
                                track-by="code"
                                label="text"
                                placeholder="Выбрать регион"
                                :options="listReg"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="() => { chgRegionOrYear(); chgData();}"
                                ref="bcRegion"
                            >
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!------------------Варианты----------------->
                    <div class="filter-block">
                        <b-form-group v-if="region_sum === 0" label="Версия бюджета">
                            <multiselect
                                v-if="region_sum === 0"
                                v-model="curVariant"
                                label="name"
                                placeholder="Выбрать вверсию бюджета"
                                :options="variantLst"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="chgData"
                                ref="bcVariant"
                            >
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!------------------Дата для свода области----------------->
                    <div class="filter-block">
                        <b-form-group v-if="region_sum === 1" label-cols-md="5" label="Бюджет на дату">
                            <date-picker
                                placeholder="Выберите дату"
                                format="DD/MM/YYYY"
                                v-model="date"
                                :disabled-date="disabledRange"
                                :append-to-body="false"
                                @input="chgData"
                                ref="bcDate"
                            />
                        </b-form-group>
                    </div>
                    <!------------------Взаимопогашаемые коды----------------->
                    <div class="filter-block">
                        <b-form-radio-group
                            v-if="region_sum === 1"
                            ref="bcVZK"
                            id="radio-group-2"
                            v-model="vzk"
                            :options="vzk_list"
                            @input="chgData"
                            name="radio-options_2"
                        >
                        </b-form-radio-group>
                    </div>
                    <!--------------- Версия ------------------->
                    <div>
                        <br>
                    </div>
                    <div class="filter-block">
                        <b-form-group>
                            <b-form-radio-group
                                ref="bcVersion"
                                id="radio-group-6"
                                v-model="version"
                                :options="versions"
                                @change="chgData"
                                name="radio-options_6"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-radio-group
                            id="radio-group-5"
                            v-model="selecteds_"
                            :options="option_"
                            name="radio-options_5"
                            @input="$emit('selectedsChanged', selecteds_)"
                        >
                        </b-form-radio-group>
                    </div>
                    <div class="filter-block" v-if="dataType === 3">
                        <b-form-group  label="Без распределяемых программ">
                            <div class="modal__toggle">
                                <b-form-checkbox v-model="dis_prog" @change="chgData" switch/>
                            </div>
                        </b-form-group>
                    </div>
                </div>
            </b-dropdown>
        </div>
        <!--       хлебные крошки-->
        <div class="filter-breadcrumb">
        <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod" @click="openFilterByRef('bcPlanYear')">
            {{ planPeriod.name }}
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && curReg" @click="openFilterByRef('bcRegion')">
            {{ curReg.code }} - {{ curReg.name_ru }}
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && curVariant && region_sum === 0" @click="openFilterByRef('bcVariant')">
            {{ curVariant.name }} - версия
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && curVariant && region_sum === 1" @click="openFilterByRef('bcDate')">
            Дата - {{ formattedDate }}
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow" @click="openFilterByRef('bcVersion')">
            {{ versions[version].text }}
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && region_sum === 1" @click="openFilterByRef('bcVZK')">
            {{ vzk_list[vzk].text }}
        </span>
        </div>
        <template>
            <balance-structure-with v-show="selecteds_ === 1"
                                    v-if="(region_sum === 0 && local_reg !== null && curVariant !== null)
                                    || (region_sum === 1 && local_reg !== null)"
                                    ref="balanceformw"
                                    :year="cYear.toString()"
                                    :obl="obl"
                                    :reg="local_reg"
                                    :variant="curVariant !== null ? this.curVariant.variant_uuid : ''"
                                    :list-reg="listReg"
                                    :version="version"
                                    :data-type="dataType"
                                    :date="date"
                                    :svod="region_sum"
                                    :vzk="vzk"
                                    :prog="dis_prog"
            >
            </balance-structure-with>
        </template>
        <template>
            <balance-structure-free v-show="selecteds_ === 2"
                                    v-if="(region_sum === 0 && local_reg !== null && curVariant !== null)
                                    || (region_sum === 1 && local_reg !== null)"
                                    ref="balanceformf"
                                    :year="cYear.toString()"
                                    :obl="obl"
                                    :reg="local_reg"
                                    :variant="curVariant !== null ? this.curVariant.variant_uuid : ''"
                                    :list-reg="listReg"
                                    :version="version"
                                    :data-type="dataType"
                                    :date="date"
                                    :svod="region_sum"
                                    :vzk="vzk"
                                    :prog="dis_prog">
            </balance-structure-free>
        </template>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import BalanceStructureFree from '@/modules/budget/BalanceStructureFree';
import BalanceStructureWith from '@/modules/budget/BalanceStructureWith';
import store from '@/services/store';
import {CUR_YEAR} from '../budget-request/components/js/budgetCurYear';
import {variantName} from '@/modules/budget/budgetCorrectHelper';

export default {
    name: 'BalanceForm',
    components: {BalanceStructureWith, BalanceStructureFree, DatePicker},
    props: {
        year: String,
        reg: String,
        dataType: Number
    },
    // watch: {
    //     reg(newVal) {
    //         this.local_reg = newVal;
    //         this.loadVariants();
    //     }
    // },
    data() {
        return {
            local_reg: '',
            day: 0,
            month: 0,
            curDate: 0,
            cYear: '',
            curYear: CUR_YEAR,
            variantBase: [],
            curVariant: null,
            variantLst: [],
            variant: '',
            curReg: null,
            selecteds_: 1,
            obl: null,
            listReg: null,
            option_: [
                {text: 'по структуре', value: 1},
                {text: 'без структуры', value: 2}
            ],
            versions: [
                {value: 0, text: 'АБП'},
                {value: 1, text: 'УЭБП'}
            ],
            years: [],
            planPeriod: null,
            version: 0,
            view: 0,
            develop: 0,
            openFilterWindow: false,
            region_sum: 0,
            vzk_list: [
                {value: 0, text: 'без ВЗК'},
                {value: 1, text: 'ВЗК'},
                {value: 2, text: 'с ВЗК'}
            ],
            vzk: 0,
            date: new Date(),
            dis_prog: this.dataType === 3
        };
    },
    created() {
        const startDate = CUR_YEAR - 1;
        const endDate = CUR_YEAR + 2;
        for (let i = endDate; i >= startDate; i--) {
            this.years.push({name: `${i} - ${i + 2}`, year: i})
        }
        if ([1, 2].includes(this.dataType)) {
            this.curYear = new Date().getFullYear() + 1;
        } else {
            this.curYear = new Date().getFullYear();
        }
        for (const y of this.years) {
            if (y.year === this.curYear) {
                this.planPeriod = y;
                this.cYear = y.year.toString();
            }
        }
        this.selecteds_ = 1;
        // this.$watch('cYear', async function () {
        //     await this.loadVariants;
        // });
        // this.$watch('curReg', async function () {
        //     this.loadVariants;
        // // });
        this.$watch('curVariant', this.chgData);
    },
    computed: {
        formattedDate() {
            return moment(this.date).format('YYYY-MM-DD');
        },
    },

    async mounted() {
        await this.getObl();
        await this.loadVariants();
        if (this.curVariant !== null) {
            this.chgData();
        }
    },
    methods: {
        disabledRange: function (date) {
            return date < new Date(this.cYear - 1, 0, 1) || date > new Date(this.cYear, 11, 31);
        },
        async filterOpen() {
            if (!Array.isArray(this.listReg)) {
                await this.loadRegions();
            }
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl.toString();
                    });
                await this.loadRegions();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },
        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl-for-svod-oblasti/' + this.obl + '/' + store.state.user.sub);
                if (response.status === 200) {
                    this.listReg = await response.json();
                    for (const item of this.listReg) {
                        if (item.code === this.reg) {
                            this.curReg = item;
                            break;
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            let refItem;
            switch (refName) {
                case 'bcPlanYear':
                    refItem = this.$refs.bcPlanYear;
                    break;
                case 'bcRegion':
                    refItem = this.$refs.bcRegion;
                    break;
                case 'bcVersion':
                    refItem = this.$refs.bcVersion;
                    break;
                case 'bcVariant':
                    refItem = this.$refs.bcVariant;
                    break;
                case 'bcDate':
                    refItem = this.$refs.bcDate;
                    break;
                default:
                    break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        // async changeFilters() {
        //     await this.loadVariants();
        //
        // },
        chgRegionOrYear() {
            this.cYear = this.planPeriod.year;
            if (this.curReg.code.endsWith('00')) {
                this.region_sum = 1;
                this.local_reg = this.curReg.code.toString();
            } else {
                this.region_sum = 0;
                this.loadVariants();
            }
        },
        chgData() {
            this.cYear = this.planPeriod.year;
            if (this.curReg != null) {
                this.local_reg = this.curReg.code.toString()
                if (this.curVariant) {
                    const data = {
                        year: this.cYear,
                        dataType: this.dataType,
                        region: this.local_reg,
                        version: this.version,
                        variant: this.curVariant.variant_uuid,
                        view: this.view,
                        develop: this.develop,
                        vzk: this.vzk,
                        date: moment(this.date).format('YYYY-MM-DD'),
                        prog: this.dis_prog
                    };
                    this.$emit('chgData', data);
                }
            }
        },
        // ------------- Варианты -------------
        async loadVariants() {
            this.local_reg = this.curReg.code.toString();
            this.variantBase = [];
            this.variantLst = [];
            this.curVariant = null;
            const param = {dataType: this.dataType, region: this.local_reg, curYear: parseInt(this.planPeriod.year)};
            try {
                const result = await fetch('/api-py/get-budg-variants-list/' + encodeURI(JSON.stringify(param)));
                if (result.status === 200) {
                    const json = await result.json();
                    console.debug("var_len", json.length);
                    const getVariantName = variantName;
                    for (const el of json) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_ru = getVariantName(el);
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_kk = getVariantName(el, 'name_kk');
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_en = getVariantName(el, 'name_en');
                    }
                    this.variantBase = json;
                    if (this.variantBase.length > 0) {
                        for (const el of this.variantBase) {
                            this.variantLst.push(this.setNameLang(el));
                        }
                        this.curVariant = this.variantLst[0];
                    } else {
                        this.makeToast('warning', 'Баланс --> Версия бюджета', 'Нет доступных версий бюджета');
                    }
                } else {
                    this.makeToast('danger', 'get-budget-variants-lst', `${result.status} - ${result.statusText}`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-budget-variants-lst', error.toString());
            }
        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (!txt) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        }
    }
};
</script>