<template>
        <div class="table-container table-balance">
            <b-table
                :fields="tableFields"
                :items="balanceForm"
                :tbody-tr-class="rowClass"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header
                no-border-collapse
            >
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="4"></b-th>
                        <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year)) + ' год' }}</b-th>
                        <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year)) + ' год' }}</b-th>
                        <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year) + 1) + ' год' }}</b-th>
                        <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year) + 1) + ' год' }}</b-th>
                        <b-th colspan="3" v-if="dataType === 1">{{ 'Прогноз на ' + (parseInt(year) + 2) + ' год' }}</b-th>
                        <b-th colspan="3" v-else>{{ 'Бюджет на ' + (parseInt(year) + 2) + ' год' }}</b-th>
                    </b-tr>
                </template>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="(data.item.parent > 0) && (data.item.parent != 5)" v-model="data.item.open"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(plan)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value.toFixed(1))) || data.value }}
                    </div>
                </template>
                <template #cell(prognoz1)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value.toFixed(1))) || data.value }}
                    </div>
                </template>
                <template #cell(deviation1)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(percent1)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(prognoz2)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value.toFixed(1))) || data.value }}
                    </div>
                </template>
                <template #cell(deviation2)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(percent2)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(prognoz3)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(deviation3)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
                <template #cell(percent3)="data">
                    <div class="text-right">
                        {{ ((isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value)) || data.value }}
                    </div>
                </template>
            </b-table>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from "@/services/store";
import VueElementLoading from "vue-element-loading";
import moment from "moment/moment";
export default {
    name: 'BalanceStructureFree',
    components: { 'loading': VueElementLoading },
    props: {
        year: String,
        obl: String,
        reg: String,
        listReg: null,
        version: Number,
        variant: String,
        dataType: Number,
        date: Date,
        svod: Number,
        vzk: Number,
        prog: Boolean
    },
    data() {
        return {
            day: 0,
            month: 0,
            curDate: 0,
            curYear: 0,
            // variant: 'f1340fa7-e90d-42a2-92ae-a6055dc1d278',
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    class: 'toggle-show'
                },
                {
                    key: 'nums',
                    label: '№'
                },
                {
                    key: 'value.name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'plan',
                    label: 'Уточненный план 2021 г. без учета ЦТ из РБ',
                    variant: 'danger'
                },
                {
                    key: 'prognoz1',
                    label: 'сумма      ',
                    variant: 'danger'
                },
                {
                    key: 'percent1',
                    label: '% роста к 2021 году',
                    variant: 'danger'
                },
                {
                    key: 'deviation1',
                    label: 'отклонение к 2021 году',
                    variant: 'danger'
                },
                {
                    key: 'prognoz2',
                    label: 'сумма       ',
                    variant: 'danger'
                },
                {
                    key: 'percent2',
                    label: '% роста к 2022 году',
                    variant: 'danger'
                },
                {
                    key: 'deviation2',
                    label: 'отклонение к 2022 году',
                    variant: 'danger'
                },
                {
                    key: 'prognoz3',
                    label: 'сумма       ',
                    variant: 'danger'
                },
                {
                    key: 'percent3',
                    label: '% роста к 2023 году',
                    variant: 'danger'
                },
                {
                    key: 'deviation3',
                    label: 'отклонение к 2023 году',
                    variant: 'danger'
                }
            ],
            balanceForm: [],
            dohody: {
                id: 1,
                type: 0,
                code: 'I',
                name_ru: 'ДОХОДЫ',
                visible: true
            },
            rashody: {
                id: 2,
                type: 0,
                code: 'II',
                name_ru: 'РАСХОДЫ',
                visible: true
            },
            open: false,
            loading: false,
            calcFlds: [
                'plan',
                'amount',
                'umount',
                'deviation1',
                'amount2',
                'umount2',
                'deviation2',
                'amount3',
                'umount3',
                'deviation3'
            ]
        };
    },
    watch: {
        svod(newSvod) {
            this.svod = newSvod;
        }
    },
    created() {
        this.$watch('variant', this.getBalance);
        this.$watch('version', this.changeVersion);
        this.$watch('prog', this.getBalance);
        this.$watch('date', this.getBalanceFreeForSvodObl);
        this.$watch('vzk', this.getBalanceFreeForSvodObl);
        this.$watch('year', () => {
            if (this.svod === 0) {
                this.getBalance();
            } else if (this.svod === 1) {
                this.getBalanceFreeForSvodObl();
            }
        });

        this.$watch('reg', () => {
            if (this.svod === 0) {
                this.getBalance();
            } else if (this.svod === 1) {
                this.getBalanceFreeForSvodObl();
            }
        });
    },
    async mounted() {
        this.listSrc = [
            { value: this.dohody, text: this.dohody.name_ru },
            { value: this.rashody, text: this.rashody.name_ru }];
        if (this.svod === 0){
            await this.getBalance();
        } else if (this.svod === 1){
            await this.getBalanceFreeForSvodObl();
        }
    },
    beforeUpdate() {
        this.day = this.padLeadingZeros(new Date().getDate(), 2);
        this.month = this.padLeadingZeros(new Date().getMonth() + 1, 2);
        this.curDate = this.day + '.' + this.month + '.' + this.year;
        if (this.dataType === 1) {
            this.tableFields[3].label = 'Уточненный план на 1 апреля ' + (parseInt(this.year)-1) + ' г. без учета ЦТ из РБ';
        } else {
            this.tableFields[3].label = 'Уточненный план на 1 апреля ' + (parseInt(this.year)-1);
        }
        this.tableFields[5].label = '% роста  к ' + (parseInt(this.year) - 1) + ' году';
        this.tableFields[6].label = 'отклонение к ' + (parseInt(this.year) - 1) + ' году';
        this.tableFields[8].label = '% роста  к ' + (parseInt(this.year)) + ' году';
        this.tableFields[9].label = 'отклонение к ' + (parseInt(this.year)) + ' году';
        this.tableFields[11].label = '% роста  к ' + (parseInt(this.year) + 1) + ' году';
        this.tableFields[12].label = 'отклонение к ' + (parseInt(this.year) + 1) + ' году';
        this.balanceForm.forEach(row => {
            if (row.hasChild) { row._colVariant = 'info'; }
        });
    },
    methods: {
        async getBalance() {
            if (this.loading) {return}
            try {
                this.loading = true;
                const that = this;
                that.balance = {};
                that.balanceForm = [];
                const response = await fetch('/api-py/balance-free-svodtable/' + that.reg + '/' + that.variant + '/' + that.year + '/' + that.dataType + '/' + that.prog);
                that.balance = await response.json();
                for (const item of that.listSrc) {
                    that.$set(item, 'num', item.value.id);
                    that.$set(item, 'nums', item.value.code);
                    that.$set(item, 'parent_id', 0);
                    that.$set(item, 'parent', item.value.id);
                    that.$set(item, '_cellVariants', { action: 'info', nums: 'info', 'value.name_ru': 'info', plan: 'warning', prognoz1: 'warning', percent1: 'warning', deviation1: 'warning', prognoz2: 'warning', percent2: 'warning', deviation2: 'warning', prognoz3: 'warning', percent3: 'warning', deviation3: 'warning' });

                    that.$set(item, '_showDetails', true);
                    that.$set(item, 'visible', true);
                    if (item.value.id != 5) {
                        that.calcFlds.forEach(field => {
                            if (!item.hasOwnProperty(field)) {
                                Object.defineProperty(item, field, {
                                    get: function () {
                                        return that.reSum(item, field);
                                    }
                                });
                            }
                        });
                    } else {
                        that.calcFlds.forEach(field => {
                            if (!item.hasOwnProperty(field)) {
                                Object.defineProperty(item, field, {
                                    get: function () {
                                        let res = 0;
                                        for (const row of that.balanceForm) {
                                            if (([1].includes(row.parent))) {
                                                res += that.reSum(row, field);
                                            }
                                            if ([2, 3, 4].includes(row.parent)) {
                                                res -= that.reSum(row, field);
                                            }
                                        }
                                        return res;
                                    }
                                });
                            }
                        });
                    }

                    that.$set(that.balanceForm, that.balanceForm.length, item);
                    for (const row of that.balance) {
                        if (item.num === row.type_id) {
                            if (row.code === '') {
                                that.$set(row, 'nums', '');
                                if (row.vt === 999) {
                                    that.$set(row, '_cellVariants', { action: 'secondary', num: 'secondary', 'value.name_ru': 'secondary', plan: 'secondary', prognoz1: 'secondary', percent1: 'secondary', deviation1: 'secondary', prognoz2: 'secondary', percent2: 'secondary', deviation2: 'secondary', prognoz3: 'secondary', percent3: 'secondary', deviation3: 'secondary' });
                                }
                            } else {
                                that.$set(row, 'nums', row.code);
                            }
                            that.$set(row, 'parent_id', item.value.id);
                            that.$set(row, 'visible', false);
                            that.$set(row, 'value.name_ru', row.name_ru);
                            that.$set(row, 'value.id', row.id);

                            that.$set(that.balanceForm, that.balanceForm.length, row);
                            if (row.vt !== 999) {
                                if (!row.hasOwnProperty('deviation1')) {
                                    Object.defineProperty(row, 'deviation1', {
                                        get: function () {
                                            return parseFloat((row.prognoz1 - row.plan).toFixed(1));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('deviation2')) {
                                    Object.defineProperty(row, 'deviation2', {
                                        get: function () {
                                            return parseFloat((row.prognoz2 - row.prognoz1).toFixed(1));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('deviation3')) {
                                    Object.defineProperty(row, 'deviation3', {
                                        get: function () {
                                            return parseFloat((row.prognoz3 - row.prognoz2).toFixed(1));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('percent1')) {
                                    Object.defineProperty(row, 'percent1', {
                                        get: function () {
                                            const rate = parseFloat(row.prognoz1 / row.plan * 100);
                                            return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('percent2')) {
                                    Object.defineProperty(row, 'percent2', {
                                        get: function () {
                                            const rate = parseFloat(row.prognoz2 / row.prognoz1 * 100);
                                            return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('percent3')) {
                                    Object.defineProperty(row, 'percent3', {
                                        get: function () {
                                            const rate = parseFloat(row.prognoz3 / row.prognoz2 * 100);
                                            return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                                        }
                                    });
                                }
                            }
                        }
                    }

                    if (!item.hasOwnProperty('percent1')) {
                        Object.defineProperty(item, 'percent1', {
                            get: function () {
                                const rate = parseFloat(item.prognoz1 / item.plan * 100);
                                return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                            }
                        });
                    }
                    if (!item.hasOwnProperty('percent2')) {
                        Object.defineProperty(item, 'percent2', {
                            get: function () {
                                const rate = parseFloat(item.prognoz2 / item.prognoz1 * 100);
                                return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                            }
                        });
                    }
                    if (!item.hasOwnProperty('percent3')) {
                        Object.defineProperty(item, 'percent3', {
                            get: function () {
                                const rate = parseFloat(item.prognoz3 / item.prognoz2 * 100);
                                return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                            }
                        });
                    }
                }
                this.changeVersion();
                this.loading = false;
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getBalance()', error.toString());
                this.loading = false;
            }
        },
        async getBalanceFreeForSvodObl() {
            if (this.loading) {return}
            try {
                this.loading = true;
                const that = this;
                that.balance = {};
                that.balanceForm = [];
                const response = await fetch('/api-py/balance-svodtable-svod-oblasti-free/' + that.reg + '/' +
                    moment(that.date).format('YYYY-MM-DD') + '/' + that.year + '/' + that.dataType + '/' + that.obl + '/' + that.vzk);
                const data = await response.json();
                that.balance = data.res;
                for (const item of that.listSrc) {
                    that.$set(item, 'num', item.value.id);
                    that.$set(item, 'nums', item.value.code);
                    that.$set(item, 'parent_id', 0);
                    that.$set(item, 'parent', item.value.id);
                    that.$set(item, '_cellVariants', { action: 'info', nums: 'info', 'value.name_ru': 'info', plan: 'warning', prognoz1: 'warning', percent1: 'warning', deviation1: 'warning', prognoz2: 'warning', percent2: 'warning', deviation2: 'warning', prognoz3: 'warning', percent3: 'warning', deviation3: 'warning' });

                    that.$set(item, '_showDetails', true);
                    that.$set(item, 'visible', true);
                    if (item.value.id != 5) {
                        that.calcFlds.forEach(field => {
                            if (!item.hasOwnProperty(field)) {
                                Object.defineProperty(item, field, {
                                    get: function () {
                                        return that.reSum(item, field);
                                    }
                                });
                            }
                        });
                    } else {
                        that.calcFlds.forEach(field => {
                            if (!item.hasOwnProperty(field)) {
                                Object.defineProperty(item, field, {
                                    get: function () {
                                        let res = 0;
                                        for (const row of that.balanceForm) {
                                            if (([1].includes(row.parent))) {
                                                res += that.reSum(row, field);
                                            }
                                            if ([2, 3, 4].includes(row.parent)) {
                                                res -= that.reSum(row, field);
                                            }
                                        }
                                        return res;
                                    }
                                });
                            }
                        });
                    }

                    that.$set(that.balanceForm, that.balanceForm.length, item);
                    for (const row of that.balance) {
                        if (item.num === row.type_id) {
                            if (row.code === '') {
                                that.$set(row, 'nums', '');
                                if (row.vt === 999) {
                                    that.$set(row, '_cellVariants', { action: 'secondary', num: 'secondary', 'value.name_ru': 'secondary', plan: 'secondary', prognoz1: 'secondary', percent1: 'secondary', deviation1: 'secondary', prognoz2: 'secondary', percent2: 'secondary', deviation2: 'secondary', prognoz3: 'secondary', percent3: 'secondary', deviation3: 'secondary' });
                                }
                            } else {
                                that.$set(row, 'nums', row.code);
                            }
                            that.$set(row, 'parent_id', item.value.id);
                            that.$set(row, 'visible', false);
                            that.$set(row, 'value.name_ru', row.name_ru);
                            that.$set(row, 'value.id', row.id);

                            that.$set(that.balanceForm, that.balanceForm.length, row);
                            if (row.vt !== 999) {
                                if (!row.hasOwnProperty('deviation1')) {
                                    Object.defineProperty(row, 'deviation1', {
                                        get: function () {
                                            return parseFloat((row.prognoz1 - row.plan).toFixed(1));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('deviation2')) {
                                    Object.defineProperty(row, 'deviation2', {
                                        get: function () {
                                            return parseFloat((row.prognoz2 - row.prognoz1).toFixed(1));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('deviation3')) {
                                    Object.defineProperty(row, 'deviation3', {
                                        get: function () {
                                            return parseFloat((row.prognoz3 - row.prognoz2).toFixed(1));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('percent1')) {
                                    Object.defineProperty(row, 'percent1', {
                                        get: function () {
                                            const rate = parseFloat(row.prognoz1 / row.plan * 100);
                                            return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('percent2')) {
                                    Object.defineProperty(row, 'percent2', {
                                        get: function () {
                                            const rate = parseFloat(row.prognoz2 / row.prognoz1 * 100);
                                            return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                                        }
                                    });
                                }
                                if (!row.hasOwnProperty('percent3')) {
                                    Object.defineProperty(row, 'percent3', {
                                        get: function () {
                                            const rate = parseFloat(row.prognoz3 / row.prognoz2 * 100);
                                            return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                                        }
                                    });
                                }
                            }
                        }
                    }

                    if (!item.hasOwnProperty('percent1')) {
                        Object.defineProperty(item, 'percent1', {
                            get: function () {
                                const rate = parseFloat(item.prognoz1 / item.plan * 100);
                                return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                            }
                        });
                    }
                    if (!item.hasOwnProperty('percent2')) {
                        Object.defineProperty(item, 'percent2', {
                            get: function () {
                                const rate = parseFloat(item.prognoz2 / item.prognoz1 * 100);
                                return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                            }
                        });
                    }
                    if (!item.hasOwnProperty('percent3')) {
                        Object.defineProperty(item, 'percent3', {
                            get: function () {
                                const rate = parseFloat(item.prognoz3 / item.prognoz2 * 100);
                                return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(1)));
                            }
                        });
                    }
                }
                this.changeVersion();
                this.loading = false;
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getBalance()', error.toString());
                this.loading = false;
            }
        },
        openAll() {
            this.open = !this.open;
            for (const row of this.balanceForm) {
                if (row.parent_id > 0) {
                    row.visible = this.open;
                    if ([1, 2, 3, 4, 6].includes(row.parent_id)) {
                        row.open = this.open;
                    }
                } else {
                    row.open = this.open;
                }
            }
        },
        changeVersion() {
            for (const row of this.balanceForm) {
                if (this.version === 0) {
                    row.prognoz1 = row.amount;
                    row.prognoz2 = row.amount2;
                    row.prognoz3 = row.amount3;
                } else {
                    row.prognoz1 = row.umount;
                    row.prognoz2 = row.umount2;
                    row.prognoz3 = row.umount3;
                }
            }
            this.open = true;
            this.openAll();
        },
        openChilds(par) {
            par.open = !par.open;

            for (const row of this.balanceForm) {
                if (par.parent === row.parent_id) {
                    row.visible = par.open;
                    if (row.hasChild && row.open !== par.open) {
                        this.openChilds(row);
                    }
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
        reSum(par, field) {
            let sum = 0;
            let BK_1 = 0;
            let BK_2 = 0;
            let BK_3 = 0;
            this.balanceForm.forEach(row => {
                if ((par.parent === row.parent_id) && ([1, 2].includes(row.parent_id) && (![5, 6, 7].includes(row.id))) && (![999].includes(row.vt))) {
                    sum += parseFloat(row[field].toFixed(1));
                }
                if ((par.parent === row.parent_id) && ([3, 4].includes(row.parent_id))) {
                    switch (row.code) {
                        case '1':
                            BK_1 = parseFloat(row[field].toFixed(1));
                        case '2':
                            BK_2 = parseFloat(row[field].toFixed(1));
                    }
                    sum = BK_1 - BK_2;
                }
                if ((par.parent === row.parent_id) && ([6].includes(par.parent))) {
                    switch (row.code) {
                        case '1':
                            BK_1 = parseFloat(row[field].toFixed(1));
                        case '2':
                            BK_2 = parseFloat(row[field].toFixed(1));
                        case '3':
                            BK_3 = parseFloat(row[field].toFixed(1));
                    }
                    sum = BK_1 + BK_3 - BK_2;
                }
            });
            return parseFloat(sum);
        },
        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    },
    computed: {}
};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>